button.create-add-buttons{
    color: #ffffff;
    background-color: #001b2b;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3); 
}

button.create-add-buttons:hover{
    color: #ffffff;
    background-color: #1565C0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); 
}

button.action-button{
    color: #ffffff;
    background-color: #001b2b;
    padding-top: 6px;
    padding-left: 16px;
    width: 5rem;
    font-family: 'Glacial Indifference', sans-serif;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3); 
}

button.action-button:hover{
    color: #ffffff;
    background-color: #1565C0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); 
}

button.submit-button{
    cursor: pointer;
    color: #ffffff;
    background-color: #001b2b;
    float: right;
    width: 5rem;
    margin-left: 10px;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px ; 
    transition: box-shadow 0.2s ease-in-out;
}

button.submit-button:hover{
    background-color: #001b2b;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
    rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
    rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}

button.cancel-button{
    background: #eeeeee;
    color: #808080;
    margin-left: 10px;
    float: right;
    width: 5rem;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px; 
    transition: box-shadow 0.2s ease-in-out;
}

button.cancel-button:hover{
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
                rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
                rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}

button.delete-button{
    color: #d32f2f;
    background-color: transparent;
    border: 1px solid #d32f2f; 
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    border-radius: 4px;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button.delete-button:hover {
    background-color: rgba(211, 47, 47, 0.05); 
}

/* Vendor and People page */
button.update-button{
    background: #001b2b;
    color: #ffffff; 
    margin-left: 10px;
    margin-right: 15px;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    float: right;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px; 
    transition: box-shadow 0.2s ease-in-out;
}

button.update-button:hover{
    background-color: #1565C0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
                rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
                rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}

button.primary--delete-button {
    background-color: #d32f2f;
    color: white;
    margin-left: 10px;
    margin-right: 12px;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    width: 6rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px; 
    transition: box-shadow 0.2s ease-in-out;
  }
  
  button.primary--delete-button:hover {
    background-color: #d32f2f;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
                rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
                rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}

/* Accounts page */

button.profile--close-button{
    background: #eeeeee;
    color: #808080;
    margin-left: 10px;
    float: right;
    font-family: 'Glacial Indifference', sans-serif;
    width: 5rem;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px; 
    transition: box-shadow 0.2s ease-in-out;
}

button.profile--close-button:hover{
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
                rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
                rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}

button.profile--add-save-button{
    cursor: pointer;
    color: #ffffff;
    background-color: #001b2b;
    float: right;
    width: 5rem;
    margin-left: 10px;
    margin-bottom: 15px;
    padding-top: 6px;
    padding-left: 16px;
    font-family: 'Glacial Indifference', sans-serif;
    padding-bottom: 6px;
    padding-right: 16px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px ; 
    transition: box-shadow 0.2s ease-in-out;
}

button.profile--add-save-button:hover{
    background-color: #001b2b;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
    rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
    rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}

button.table-button {
    font-family: 'Glacial Indifference', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #3c56ff;
    text-transform: none;
    justify-content: flex-start;
    text-align: left;
    text-transform: none !important;

  }