.navbar1 {
  /* margin: 10px; */
  /* box-shadow: 2 px 2px #7171aa; */
  /* box-shadow: 0 2px 2px 0px rgba(9, 9, 9, 0.065); */
  height: 40px;
  display: flex;
  margin-left: 25px;
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 1.2rem;
  position: static;
  /* top: 20; */
  z-index: 20;
  /* margin-top: 40px; */
  /* margin-bottom: 30px; */
}
.row1 {
  display: flex;
  flex-direction: row;
}

.nav-container1 {
  display: flex;
  /*justify-content: center;*/
  width: 99%;
  /* height: 80px; */
}

.nav-container1 h5 {
  color: #6c6c6c;
  cursor: pointer;
  margin-top: 11px;
}

/* .nav-titles */

.nav-titles:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
  margin-top: 10px;
}

.nav-titles {
  margin-right: 20px;
  color: #000080;
  margin-top: 8px;
}

.nav-titles:hover:after {
  width: 100%;
  background: #001b2b;
}

.brandicon {
  margin: 10px;
}
.nav_img {
  margin-top: 10px;
}

/* :end nav-title*/

.activetitle {
  margin-right: 20px;
  margin-top: 8px;
  color: #000080;
}

.activetitle:after {
  content: "";
  display: block;
  height: 3px;

  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
  margin-top: 10px;
  width: 100%;
  background: #001b2b;
  color: #000080;
}

.nav-logo1 {
  color: #000075a6;
  /* align-items: center; */
  font-weight: 500;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 22px;
  flex-grow: 1;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.headerbuttons {
  margin-left: 300px;
  z-index: 0px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}

.button {
  /* margin-left : 820px; */
  float: left;
  background-color: #00008076;
  border-radius: 10px;
  border: none;
  color: #fff;
  height: 30px;
  width: 78px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  /* margin-bottom: 20px; */
  /* justify-content: center; */
  /* align-items: center; */
}

/* CSS */
.button-313 {
  display: block;
  margin-left: auto;
  margin-right: 40px;
  /* appearance: none; */
  /* margin-right: 10px; */
  margin-top: 7px;
  background-color: #001b2bec;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  /* box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0; */
  /* box-sizing: border-box; */
  color: #fff;
  cursor: pointer;
  /* display: flex; */
  /* display: inline-block; */
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  /* line-height: 20px; */
  /* padding: 6px 16px; */
  /* position: relative; */
  /* text-align: center; */
  float: right;
  text-decoration: none;
  /* user-select: none; */
  /* -webkit-user-select: none; */
  /* touch-action: manipulation; */
  /* vertical-align: middle; */
  /* white-space: nowrap; */
}

.button-313:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-313:hover {
  background-color: #001b2b;
}

.button-313:focus {
  box-shadow: #001b2b 0 0 0 3px;
  outline: none;
}

.button-313:disabled {
  background-color: #001b2b;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-313:active {
  background-color: #4285f4;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}

.button icon {
  size: 10px;
}

.iconplus {
  font-size: large;
  margin-left: 10px;
}

.nav-menu1 {
  display: flex;
  list-style: none;
  /* text-align: center; */
  margin-right: 1rem;
  margin-top: 15px;
}
.largeinputtype {
  width: 29%;
  height: 2.5rem;
  padding: 0.3rem;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: transparent;
  margin-top: 15px;
}

.nav-links1 {
  color: rgb(47, 35, 35);
  text-decoration: none;
  /* padding: 0.5rem 3rem; */
  padding: 20px;
  height: 100%;
  border-bottom: 3px solid transparent;
  /* size:; */
}

.fa-code1 {
  margin-left: 1rem;
}

.nav-item1 {
  line-height: 40px;
  margin-right: -3rem;
  /* margin-top: 10px; */
}

.nav-item1:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.avatar1 {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  background: #bbbbbb;
  margin: 10px;
}

.avatar1 img {
  max-width: 100%;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.nav-item1:hover:after {
  width: 100%;
  background: #001b2b;
}

.nav-item1 .active:after {
  /* color: #ffdd40;
    border: 1px solid #ffdd40; */
  width: 100%;
  background: #001b2b;
}
.button-22 {
  align-items: center;
  height: 35px;
  width: 90px;
  appearance: button;
  background-color: #4285f4;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-family: "RM Neue", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 10px 21px;
  text-align: center;
  text-transform: none;
  transition: color 0.13s ease-in-out, background 0.13s ease-in-out,
    opacity 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-22:active {
  background-color: #006ae8;
}

.button-22:hover {
  background-color: #1c84ff;
}

/* CSS */
.button-38 {
  background-color: #ffffff;
  height: 35px;
  width: 90px;
  border: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #111827;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 5px;
  text-align: center;
  text-decoration: none #d1d5db solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-38:hover {
  background-color: rgb(249, 250, 251);
}

.button-38:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button-38:focus-visible {
  box-shadow: none;
}

.nav-icon1 {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu1 {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    /* left: -110%; */
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu1.active {
    background: #ffffff;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item1 .active {
    color: #000075a6;
    border: none;
  }

  .nav-links1 {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon1 {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000075a6;
  }
}
