* {
  margin: 0;
  padding: 0;
  color: "#001b2b";
  /* box-sizing: border-box; */
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
.NavLink {
  text-decoration: none;
}
.link1 {
  text-decoration: none;
}
main {
  /* padding: 10px; */
  height: 100%;
  width: 100%;
}
body {
  font-family: 'Glacial Indifference', sans-serif;
  background: #eeeeee;
}
.MuiTypography-root {
  font-family: 'Glacial Indifference', sans-serif;
}
a {
  font-family: 'Glacial Indifference', sans-serif;
  text-decoration: none;
  text-transform: capitalize;
}
.fontfont {
  font-family: 'Glacial Indifference', sans-serif;
  text-transform: capitalize;
  text-decoration: none;
}
.login_background {
  height: 100%;
  /* position: fixed; */
  padding: 2%;
  width: 100%;
  background: linear-gradient(to bottom, #001b2b -150%, #656565 83%);
}
a {
  text-transform: initial;
}
.firstletter::first-letter {
  text-decoration: none;
  text-transform: capitalize;
}
/* /////nav */
.divider {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #60606080;
}
.dividern {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #60606080;
}
.nav {
  background-color: #868686;
  /* box-shadow: 2 px 2px #7171aa; */
  box-shadow: 0 2px 2px 0px rgba(9, 9, 9, 0.23);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}
.input-nav {
  opacity: 0.5;
  border-radius: 15px;
  height: 80%;
  /* border: gray; */
  border-width: 1px;
  border-color: #000080;
  /* position: fixed; */
}
.wrapper {
  position: relative;
  width: 90%;
}
.nav-content {
  padding-left: 2.5rem;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.btn{
  background-color: #001b2b;
  color: #eeeeee;
}
.row {
  display: flex;
  flex-direction: row;
}

.icon-search {
  height: 1.5rem;
  width: 1.5rem;
  /* background-color: red; */
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  color: rgb(55, 55, 55);
}
.icon {
  z-index: -1;
  color: #ffffff;
  padding: 10px;
  /* height: ; */
}
.active .icon {
  color: #4285f4;
}
.icon:hover {
  cursor: pointer;
}
.dropdownicon:hover {
  cursor: pointer;
}

.input-nav {
  height: 30px;
  width: 30%;
  box-sizing: border-box;
  padding-left: 2.5rem;
  border-radius: 10px;
  border: none;
  /* border-color:gray; */
  /* border-style:solid; */
  background-color: rgb(235, 235, 235);
}
.profile-nav {
  max-width: 30px;
  position: static;
  background-color: black;
  border-radius: 100%;
}
.profile-nav img {
  width: 100%;
  display: block;
  border-radius: 100%;
}
.profile-nav ::before {
  content: "";
  position: absolute;
  inset: -15px;
  border-top-color: #000080;
  border-bottom-color: #aa2222;
  border-radius: 100%;
  z-index: -1;
  animation: spin 3s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
/* Sidebar */
.main-container {
  display: flex;
}
.sidebar {
  color: #ffffff;
  height: 100%;
  background: #001b2b;
  overflow-x: hidden;
  /* justify-content: center; */
  position: fixed; /* overflow: hidden; */
  /* border-color: #000080; */
  box-shadow: 2px 2px 10px #0000801b;
  z-index: 11;
}
.container {
  margin-left: 200px;
  transition: margin-left 0.2s ease-in;
  /* padding: 10px; */
  box-sizing: border-box;
}
.NonConfermance {
  box-shadow: #d603034d 0px 0px 0px 1px;
}
.Confermance {
  box-shadow: #03d63490 0px 0px 0px 1px;
}
.Partial {
  box-shadow: #d6af0390 0px 0px 0px 1px;
}

.notification_link:hover {
  background-color: #000;
}

.ql-editor {
  min-height: 100px;
}

.openButton {
  margin-top: 3px;
  background: none;
  border: 1px solid;
  border-radius: 50px;
}
.openButton:hover {
  background: white;
}
.generateButton {
  margin-top: 3px;
  background: none;
  border: 1px solid;
  border-radius: 50px;
}
.generateButton:hover {
  background: rgba(255, 79, 79, 0.678);
}
.activecontainerr {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.container.inactive {
  margin-left: 60px;
}
.top_section {
  background-color: #001b2b;
  z-index: 30;
  position: fixed;
  /* gap: 10px; */
  /* justify-content: space-between; */
  /* padding: 15px 10px; */
}
.logo {
  font-size: 18px;
  margin-left: 21%;
  margin-top: 10px;
  width: 100px;
  /* line-height: 0; */
}
.bars {
  background-color: #001b2b;
  width: 50px;
  height: 50px;
  margin: 15px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 40px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  height: 25px;
}
.search ::placeholder {
  font-size: 16px;
}

.routes {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin-bottom: 60px;
}
.link {
  /* justify-content: center; */
  display: flex;
  text-decoration: none;
  color: #000080;
  gap: 7px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.Topnav_search {
  height: 35px;
  min-width: 140px;
  display: none;
}
.topnav {
  display: flex;
}
.Topnav_search:focus {
  /* height: 35px; */
  min-width: 200px;
  width: 220px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}
/* chakbox */
.checkbox-wrapper-48 {
  --gray: #636e72;
  --very-light-gray: #eee;
  --light-gray: #9098A9;
  --x-light-gray: #dfe6e9;
  --gradient: #4285f4;
}

.checkbox-wrapper-48 label {
  font-size: 1.35em;
}

/* CORE STYLES */
.checkbox-wrapper-48 input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  font: inherit;
  border: 0.1em solid var(--light-gray);
  margin-bottom: -0.125em;
}

.checkbox-wrapper-48 input[type=checkbox] {
  border-radius: 0.25em;
}

.checkbox-wrapper-48 input:checked {
  border-color: transparent;
  background: #4285f4 border-box;
  box-shadow: 0 0 0 0.1em inset #fff;
}

.checkbox-wrapper-48 input:not(:checked):hover {
  border-color: transparent;
  background: linear-gradient(#fff, #fff) padding-box, var(--gradient) border-box;
}
/* end chakbox */
.grid1 {
  display: grid;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.grid2 {
  display: grid;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}
.grid3 {
  display: grid;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.grid4 {
  display: grid;
  opacity: 0;
  transition: opacity 2.5s ease-in-out;
}
.grid5 {
  display: grid;
  opacity: 0;
  transition: opacity 3s ease-in-out;
}
.grid6 {
  display: grid;
  opacity: 0;
  transition: opacity 3.5s ease-in-out;
}
.grid7 {
  display: grid;
  opacity: 0;
  transition: opacity 3.5s ease-in-out;
}
.grid8 {
  display: grid;
  opacity: 0;
  transition: opacity 3.5s ease-in-out;
}
.grid9 {
  display: grid;
  opacity: 0;
  transition: opacity 3.5s ease-in-out;
}
.grid10 {
  display: grid;
  opacity: 0;
  transition: opacity 3.5s ease-in-out;
}

.show {
  opacity: 1;
}
.modal {
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  max-width: 500px;
  width: 80%;
  padding: 1rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close-button {
  font-size: 1.4rem;
  color: #000;
  cursor: pointer;
  border: none;
  background: transparent;
}
.link:hover {
  text-decoration: none;
  border-right: 7px solid #4285f4;
  transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover .static {
  opacity: 0;
}
.link_text:hover .static {
  opacity: 0;
}
.static {
  position: absolute;
  background: #001b2b;
}
.static1 {
  position: absolute;
  background-color: #ffffff;
}
.static:hover {
  opacity: 0;
}
.gapanimation {
  animation: fadeIn 1s both;
  transition: opacity 0.5s ease-in-out;
}
.gapcritical {
  border-left: 7px solid;
}
.gapcritical:hover {
  border-left: 9px solid;
}
.active {
  border-right: 7px solid #4285f4;
  text-decoration: none;
  /* border-top-right-radius: 10px; */
  /* border-bottom-right-radius:10px ; */
  /* padding: 10px 10px; */
  display: flex;
  flex-direction: row;
  color: #4285f4;
  /* border-radius: 15px; */
  /* justify-content: s; */
}

.side-menu-footer {
  background: #001b2b;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 40px;
  /* overflow: hidden; */
  /* margin-left: -9px; */
  /* left: 0; */
  /* border-radius: 100%; */
  /* padding: 7px; */
  /* box-sizing: border-box; */
  /* color: #000080; */
  /* max-width: 50%; */
}

.side-menu-footer .avatar {
  margin-left: 4px;
  /* position: fixed; */
  /* height: 20x; */
  /* overflow: hidden; */
  /* border-radius: 50%; */
  /* display: inline-block; */
  /* background-color: transparent; */
  /* margin: 20px; */
}

.side-menu-footer .user-info {
  /* display: inline-block; */
  /* margin: 10px; */
  color: #fff;
  /* position: absolute; */
  /* opacity: 1; */
  transition: opacity 0.2s ease-in;
  width: 20%;
}
.side-menu-footer .user-info h5 {
  font-size: 16px;
  color: #fff;
}

.active .link_text {
  color: #e6e9ec;
}
.link_text::first-letter {
  text-transform: uppercase;
}
.firstletter1 {
  text-decoration: none;
}
.firstletter1::first-letter {
  text-transform: uppercase;
}
.link_text {
  /* margin: auto; */
  white-space: break-spaces;
  color: #b5bfc7;
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* white-space: nowrap; */
  /* font-size: 15px; */
  /* font-weight: 500; */
}
.link_text:hover {
  cursor: pointer;
  text-align: left;
  white-space: break-spaces;

  width: fit-content;
}
.sidebar-tooltip {
  font-size: large;
  color: #ffff;
  text-decoration: none;
  text-transform: capitalize;
}
/* loader start */
.spinner {
  margin: auto;
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}
.spinner.center {
  
  margin: auto;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #69717d;
  }

  100% {
    background-color: transparent;
  }
}
/* end loader */
.openid-container {
  /* Other styles... */
  opacity: 1; /* Initial opacity */
  transition: opacity 1s ease-in; /* Transition property */
}

.openid-container.fade-in {
  opacity: 1; /* Opacity when the element is visible */
}
.menu {
  display: flex;
  color: #ffff;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
    /* padding: 10px 10px; */
  align-items: center;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: transparent 0.5px solid;
}

.container {
  width: 150px;
  height: 150px;
  display: flex;
  place-content: center;
  overflow: hidden;
  background: rgba(6, 6, 6, 0.2);
  border-radius: 30px;
}

.item {
  width: 56%;
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
  transform: rotate(180deg);
}

/* aewsredtfgyu */

/* ======================== */

.input-group {
  position: relative;
}

/* .input {
  padding: 10px;
  border: none;
  border-radius: 4px;
  font: inherit;
  background-color: transparent;
  outline: 2px solid #ffffff;
} */

/* .input-label {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, -10px);
  transition: transform 0.25s;
}

.input:focus + .input-label,
.input:valid + .input-label {
  transform: translate(10px, -14px) scale(0.8);
  color: #d1c5fc;
  padding-inline: 5px;
  background-color: #000080;
}

.input:is(:focus, :valid) {
  outline-color: #d1c5fc;
} */

/* //////loginPage */

.manilogincontainer {
  justify-content: center;
  height: 100%;
  width: 100%;
}

.center_box {
  background-color: #000080;
  height: 50%;
  width: 25%;
}
/* ////loginpage */
.Maincontainer {
  text-align: center;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  /* height: 75vmin; */
}

.loginform {
  display: inline-block;
  background: rgb(255, 255, 255);
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  padding: 1rem;
  /* margin: 2rem 0 1rem 0; */
  /* height: 200%; */
  width: 33%;
  /* margin-top: 20vmin; */
  left: 32%;
  /* text-align: center; */
}

.formControl {
  width: 85%;
  height: 2.2rem;
  padding: 0.3rem;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: #0000801a;
  margin-top: 15px;
}
a::first-letter {
  text-transform: uppercase;
}
/* .right-label {
  float: right;
  cursor: pointer;
  margin-right: 25px;
  font-size: 12px;
  margin-top: 5px;
} */

#sub_btn {
  /* display: block; */
  width: 20rem;
  height: 35px;
  padding: 0.3rem;
  border: none;
  background: #4285f4;
  color: #fff;
  border-radius: 3px;
  margin-top: 35px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.loginfooter {
  text-align: center;
  margin-top: 40px;
  /* margin-top: 10px; */
}
.loginform ::placeholder {
  font-size: small;
}
.signupbutton {
  width: 140px;
  height: 35px;
  background-color: #4285f4;
  color: aliceblue;
  border-radius: 10px;

  font-size: 14px;
  margin-top: 20px;
  margin-left: 5%;
}
.button-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  border-radius: 6px;
  border: none;
  color: #fff;
  background: linear-gradient(180deg, #4285f4 0%, #4285f4 100%);
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  height: 35px;
  margin-top: 25px;
  margin-left: 30px;
  font-size: 14px;
}

.button-10:hover {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}
.button-2 {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 5px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  height: 35px;
  margin-top: 25px;
  margin-left: 65%;
}
/* login page end */

/* ourservicepage start */

.icon-box-address .icon {
  width: 64px;
  height: 64px;
  /* background: #000080; */
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  color: rgb(255, 255, 255);
  /* overflow: hidden; */
}

.icon-box-address .icon i {
  font-size: 28px;
}
.brieflable {
  opacity: 0;
}
.newassesmentsteps:hover .brieflable {
  opacity: 1;
}
.icon-box-address h4 {
  margin-bottom: 15px;
  font-size: 24px;
}

.icon-box-address h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}

.icon-box-address p {
  line-height: 24px;
  font-size: 14px;
  font-family: 'Glacial Indifference', sans-serif;
  margin-bottom: 0;
}

.icon-box-address:hover {
  transform: translateY(-10px);
  border-color: #001b2b;
}

.icon-box-address {
  padding: 25px;
  transition: all ease-in-out 0.3s;
  background: #fff;
  box-shadow: 0px 5px 90px 0px #f0f0ff;
  border-radius: 18px;
  border-bottom: 5px solid #fff;
  /* margin-top: "10%"; */
}

/* anther */

.e-search:before {
  content: "e993";
}

.e-user:before {
  content: "e7b4";
}

.e-icons {
  color: #ffffff;
  font-size: 17px;
  /* margin-top: 5px; */
  position: absolute;
  margin-top: 9px;

  left: 10px;
}

.App {
  text-align: center;
}

.app {
  /* background-color:#61dafb; */
  height: fit-content;
  width: fit-content;
}

.lrimg {
  height: 100%;
  width: 15%;
  position: absolute;
}

.lrimg1 {
  height: 100%;
  width: 15%;
  position: absolute;
  margin-left: -1%;
}

.kuttycolor {
  float: right;
  margin-top: -25%;
  margin-right: 15%;
}

/* // */

h2 {
  text-align: left;
  font-family: 'Glacial Indifference', sans-serif;}

.Maincontainer {
  text-align: center;
  height: "100%";
  /* height: 75vmin; */
}

.signinbutton {
  width: 20px;
  border-radius: 15px;
  background-color: #000080;
  margin-left: 48%;
}

.Signin {
  /* background-color: #282c34; */
  /* margin-top: 20px; */
  background-size: contain;
  color: #000080;

  position: absolute;
  text-align: center;
  right: 99vmin;
  margin-top: 5vmin;
  font-size: 50px;
  /* background-color: #000080; */
}

.createaccount {
  color: #000080;
}

.Signin2 {
  /* background-color: #282c34; */
  /* margin-top: 20px; */
  background-size: contain;
  color: hsl(0, 93%, 49%);
  position: absolute;
  text-align: center;
  right: 94vmin;
  margin-top: 5vmin;
  font-size: 50px;
  /* background-color: #000080; */
}

.Signinreg {
  /* background-color: #282c34; */
  margin-top: 5px;
  background-size: contain;
  color: #000080;
  /* background-color: #000080; */
}

.loginform {
  display: inline-block;
  background: rgb(255, 255, 255);
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  padding: 0.7rem;
  /* margin: 2rem 0 1rem 0; */
  height: 60vmin;
  width: 30%;
  margin-top: 20vmin;
  position: absolute;
  left: 33.3%;
}

/* ////////profile add product */
.openid {
  width: 70vh;
  /* margin: 40px; */
  display: flex;
  justify-content: space-between;
}

.services {
  display: flex;
  justify-content: space-between;
  margin-left: 120px;
}

.first-division {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0.5rem 0;
  width: 100%;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 5px 10px;
}
.first-division button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: rgb(0, 208, 255);
  border: 1px solid rgb(0, 208, 255);
}
.second-division button {
  margin-left: 10px;
  color: rgba(255, 0, 0, 0.38);
  border: 1px solid rgba(255, 0, 0, 0.455);
}

/* form {
    display: inline-block;
    /* background: #ddd; */
/* border: 1px solid #ddd; */
/* border-radius: 2px; */
/* padding: 2rem; */
/* margin: 2rem 0 1rem 0; */
/* height: 50vmin; */
/* width:25%; */
/* margin-top: 20vmin; */
/* position: absolute; */
/* left: 70vmin; */
/* background-color: rgb(102, 102, 122); */
/* }  */
.regform {
  display: inline-block;
  /* background: #ddd; */
  /* border: 1px solid #ddd; */
  border-radius: 2px;
  /* padding: 2rem; */
  /* margin: 2rem 0 1rem 0; */
  height: 30vmin;
  width: 40%;
  margin-top: 3vmin;
}

.reglogo {
  height: 50vmin;
  width: 73vmin;
  position: fixed;
  margin-top: 6%;
  left: 5vmin;
}

.main {
  height: 100%;
}

.flex1 {
  width: auto;
  margin-left: 10;
}


.largeinputtype {
  height: 2.5rem;
  padding: 0.3rem;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: transparent;
  margin-top: 15px;
}

.multiselect {
  width: 100%;
  border: none;
}

.inputlikecontainer {
  width: 20rem;
  height: 2.5rem;
  /* padding: 0.3rem; */
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: transparent;
  /* margin-top: 15px; */
}

.formControl {
  width: 85%;
  height: 1.7rem;
  padding: 0.3rem;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: #0000801a;
  margin-top: 15px;
}

.loginfooter {
  text-align: center;
  top: 20px;
  /* margin-top: 10px; */
}

.profileinput {
  width: 16rem;
  height: 2rem;
  border: none;
  background-color: #eeeeee;
}
.profileinputaddp {
  width: 16rem;
  height: 2.5rem;
  border: none;
  background-color: #80808030;
  border: #000;
  border-width: 1px;
  margin-top: 10px;
}

::placeholder {
  margin-left: -10px;
  /* font-weight: normal; */
  /* font-size: small; */
  /* color: violet; */
}

.submitetext {
  color: #fff;
  size: 20px;
}

#sub_btn {
  /* display: block; */
  width: 20rem;
  height: 35px;
  padding: 0.3rem;
  border: none;
  background: #000080;
  color: #fff;
  border-radius: 3px;
  margin-top: 35px;
  border-radius: 8px;
}

.textheader {
  position: absolute;
  color: #000080;
}

.whizzcheading {
  flex-direction: row;
}

#sub_btn:hover {
  background: rgb(255, 0, 0);
  transition: all 0.5s;
}

.backtohome {
  margin-top: 30px;
}

footer p {
  margin: 0;
  font-size: 0.9rem;
}

footer {
  position: absolute;
  text-align: center;
  align-content: center;
  align-items: baseline;
  margin-top: 85vmin;
  margin-left: 85vmin;
}

form p {
  margin-top: 10px;
}

.regform p {
  margin-top: 10px;
}

.lrimg2 {
  height: 30%;
  width: 15%;
  float: right;

  /* margin-top:-20%; */
}

.lrimg3 {
  height: 100%;
  width: 15%;
  position: absolute;
  right: 7px;
}

.lrimg4 {
  height: 100%;
  width: 15%;
  position: absolute;
  /* right: -1px;  */
}

.Pageheader {
  margin-right: 10px;
}

.element {
  width: 100%;
  height: 100vmin;
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/***    Register Page     ***/
#checkbox {
  width: 1rem;
  left: 20px;
}

.cheakboxallain {
  flex-direction: row;
}

form span {
  font-size: 0.8rem;
}

#reset_pass_lbl {
  float: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* //////////////////////sidebar */
* {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
}

/* li {
  list-style: none;
} */

/* width */
::-webkit-scrollbar {
  width: 5px;
  cursor:all-scroll;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: #808080 0px 2px 8px 0px;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #001b2b;
  display: block;
}

.side-menu {
  position: fixed;
  background: rgb(255, 255, 255);
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  align-self: center;
  align-content: center;
  align-items: center;
  transition: width 0.2s ease-in;
}

.paddind2 {
  padding: 30px 10px;
}

.side-menu.inactive {
  width: 60px;
}

.side-menu .top-section {
  position: relative;
}

.side-menu .top-section .logo {
  width: 100%;
  height: 40px;
  left: 10px;
  margin-left: 7px;
  overflow: hidden;
  display: inline-block;
}
.menulogo {
  height: 70px;
  display: flex;
  flex-direction: column;
  /* margin: auto; */
  margin-left: 10px;
  width: 100%;
  justify-content: center;
  margin-top: 5px;
}
.anim-typewriter {
  border-right: 0.15em solid transparent;
  white-space: nowrap;
  overflow: hidden;
}

.side-menu .top-section .logo img {
  max-width: 100%;
  max-height: 100%;
}

.side-menu .top-section .toggle-menu-btn {
  /* background-image: linear-gradient(to bottom, #00008020, #0000801a, #0000800e, #0000801a, #00008020); */
  color: #00007573;
  font-size: 18px;
  position: absolute;
  top: 60%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.side-menu .search-controller {
  margin-top: 30px;
  color: #e0e0e0;
  position: relative;
}

.side-menu .search-controller .search-btn {
  /* width: 20px; */
  /* height: 25px; */
  position: absolute;
  background: transparent;
  border: 0;
  top: 3px;
  margin-left: 1px;
  font-size: 17px;
  color: #ffffff;
}

.side-menu .search-controller input[type="text"] {
  border: 0;
  outline: none;
  height: 33px;
  background: #ececec;
  border-radius: 10px;
  display: block;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
}

.side-menu .main-menu .menu-item {
  color: #00000090;
  text-decoration: none;
  font-size: 15px;
  display: block;
  cursor: pointer;
  margin-top: 10px;
  /* margin: 10px; */
  /* height: 100%; */
}

.side-menu .main-menu .menu-item.active {
  background: #00008020;
  /* box-shadow: #7c7cb956 0px -23px 25px 0px inset, #7c7cb956 0px -36px 30px 0px inset, #7c7cb956 0px -79px 40px 0px inset, #7c7cb956 0px 2px 1px, #00000017 0px 4px 2px, #00000017 0px 8px 4px; */
  /* background-image: linear-gradient(to bottom, #00008039, #0000801a, #0000800e, #0000801a, #00008039); */
  border-radius: 10px;
  height: 35px;
  color: #000000f8;
}

.active-logo {
  height: 35px;
  margin-left: 10px;
  margin-top: 3px;
  /* bottom: 20px; */
  /* background: #000; */
}

.side-menu .main-menu {
  margin: 15px 0;
  position: relative;
  max-height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* not required any more */
.shadow {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px; */
  /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
  /* box-shadow: #00008010 0px -23px 25px 0px inset, #00008010 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, #00008010 0px 32px 16px; */
  /* box-shadow: #00008010 0px -23px 25px 0px inset, #00008010 0px -36px 30px 0px inset, #00008010 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, #00008010 0px 4px 2px, #00008010 0px 8px 4px, #00008010 0px 16px 8px, #00008010 0px 32px 16px; */
}

.shadow:hover {
  box-shadow: #00008010 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.shadowpie {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.shadowpie:hover {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.side-menu .main-menu .menu-item .menu-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  /* margin-top: 10px;*/
}

.side-menu .main-menu .menu-item span {
  position: absolute;
  display: inline-block;
  /* margin-top: -1; */
  line-height: 35px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.side-menu.inactive .main-menu .menu-item span {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.side-menu .main-menu .sub-menu {
  color: #333;
  margin-left: 10px;
  border-left: 1px solid #666;
  box-sizing: border-box;
  padding-left: 30px;
  max-height: 0;
  overflow: hidden;
  /* margin-top: 15px; */
  transition: max-height 1s ease-in;
}

.side-menu .main-menu .sub-menu.active {
  max-height: 200px;
}

.side-menu .main-menu .sub-menu a {
  display: block;
  margin: 5px 0;
  font-size: 15px;
  text-decoration: none;
  color: #333;
  box-sizing: border-box;
  padding: 5px;
}

.side-menu .main-menu .sub-menu a.active {
  /* change in background, not recorded in video */
  /* background: #222; */
  background: #00008040;
  display: block;
  color: #000000;
  border-radius: 5px;
  margin-top: 10px;
  /* margin: 5px; */
  /* color: #fff; */
}

.divider {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #00008080;
}

.container111 {
  transition: margin-left 0.5s ease-in;
  margin-top: 65px;
  box-sizing: border-box;
}

.container111.inactive {
  margin-left: 54px;
}
.container111.noinactive {
  margin-left: 250px;
}

/* ////sidebar end */
.flexdirusercard {
  display: flex;
  flex-direction: row;
  /* background: #000; */
}

.userprofilepic {
  width: 50px;
  height: 50px;
}

.usernamemaildiv {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-left: 40px;
  /* background-color: #000080; */
  width: 30%;
}

.Usermail {
  color: #836f00;
  font-size: 10px;
  margin-left: 5px;
  font-family: "DM Serif Text", serif;
}

.nextpng {
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin-left: 4 0px;
}

.e-people {
  color: #616161;
}

.e-home {
  color: #616161;
}

.e-form-data {
  color: #616161;
}

.e-comments {
  color: #616161;
}

.e-description {
  color: #616161;
}

.e-timeline-work-week {
  color: #616161;
}

.e-chart {
  color: #616161;
}

.bi-speedometer2 {
  color: #000080;
}

/* /////////user/ */
.addusercontainer {
  width: 120px;
  margin-top: 40px;
  margin-bottom: 40px;
  height: 40px;
  border-radius: 15px;
  background-color: #000080;
  box-shadow: 2 px 2px #7171aa;
}

.addusercontainer:hover {
  /* background-color: #61dafb; */
  /* background: #7171aa; */
  transition: all 0.5s;
  transform: scale(1.1);
  cursor: pointer;
}

.buttonhover:hover {
  cursor: pointer;
  background: #7171aa;
}

.progresstext {
  font-size: small;
  font-family: 'Glacial Indifference', sans-serif;
}

.popupmodel {
  width: 800px;
  height: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: rgb(255, 255, 255);
  color: #000;
  display: flex;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  flex-direction: column;
}

.popupmodelallert {
  width: 70%;
  margin: 40px;
  height: 200px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: rgb(255, 255, 255);
  color: #000;
  display: flex;
  grid-template-columns: 1fr 1fr;
  position: relative;
  /* z-index: 100; */
  border-radius: 10px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  /* margin-top: 100; */
}

.usericondir {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  flex-direction: row;
}

/* ////////// */
.icon-box {
  padding: 30px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border-radius: 18px;
  border-bottom: 5px solid #fff;
  height: 100%;
  /* added this */
  max-height: 220px;
  /* edited here */
  font-size: small;
  overflow-y: hidden;
  cursor: pointer;
  transition: max-height 1.5s;
  /* added this */
}

.icon-box:hover {
  border-color: #001b2b;
  max-height: 400px;
  /* edited and removed transition declaration */
}

.ourservicescard {
  padding: 10px;
  /* margin-bottom: 50px; */
  /* margin-top: 30px; */
}

.addressservicescard {
  /* margin-top: "10px"; */
  padding: 10px;
  /* width: 30%; */
  margin-top: 10px;
}

.icon-box .icon {
  width: 64px;
  height: 64px;
  /* background: #000080; */
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  color: rgb(255, 255, 255);
  /* overflow: hidden; */
}

.icon-box .icon i {
  font-size: 28px;
}

.icon-box h4 {
  margin-bottom: 15px;
  font-size: 24px;
}

.icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}

.icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.process-maincontainer {
  margin: 35px;
  width: 96%;
  margin-top: -15px;
}

.policyprocedurecard {
  height: 40px;
  width: 45%;
  border: 10px;
  border-color: #000000;
  border-width: 10;
  border-radius: 10px;
  margin-top: 12px;
  /* // color: "#000080", */
  display: flex;
  flex-direction: row;
}

.policyprocedurecard:hover {
  transform: translateY(-5px);
  border-color: #0fce19;
}

.services .icon-box:hover h4 a {
  color: #000080;
}

.swipertab :hover {
  cursor: pointer;
}
.react-bs-table-pagination > .row > div > span {
  float: left;
}
.table_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-item.active {
  display: none;
}
.logincardshadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
@font-face {
  font-family: 'Glacial Indifference', sans-serif;
  src: url("../src/font/DMSerifText-Italic.ttf") format("woff2"),
    url("../src//font//DMSerifText-Regular.ttf") format("woff");
  /* Add additional font formats if necessary */
  /* Specify the correct file paths and formats for your font files */
}

/* Optionally, you can define font styles for specific elements using the font-family you specified */

.shadowheader {
  box-shadow: #001b2b4f 0px 1px 4px;
}
.scrollbarr{
    animation: slideDown 0.7s both;
  scrollbar-width:var(--brdr-actv);
    /* For Firefox */
    /* For Firefox */
}
.controlslist {
    border-bottom: 1px solid #bfbfbf;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  height: fit-content;
  flex-direction: row;
  display: flex;
  animation: slideDown 0.7s both;

}
.layout-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.controlslist:hover {
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
}
.controlslist:hover + .progressbarhover {
  opacity: 0;
}

.zoomin {
  animation: fadeIn 2.7s both;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.gaplist {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #eeeeee;
  border-radius: 10px;
  padding: 5px;
  height: fit-content;
  margin-top: 70px;
  flex-direction: row;
  display: flex;
}

.gaplist:hover {
  transform: translateY(-5px);
  border-color: #000000;
  cursor: pointer;
}
.HeadText {
  text-align: center;
  background-color: #001b2b;
  color: aliceblue;
  padding: 1%;
}
ul:li {
  color: #001b2b;
  font-size: medium;
  line-height: 5px;
}
.para {
  color: #001b2b;
  font-size: medium;
}
.auditList {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px;
  height: 40px;
  margin: 10px 50px 0px 50px;
  flex-direction: row;
  display: flex;
  margin-top: 20px;
}

.auditList:hover {
  transform: translateY(-5px);
  border-color: #000000;
  cursor: pointer;
}

.icon-box-address .icon {
  width: 64px;
  height: 64px;
  /* background: #000080; */
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  color: rgb(255, 255, 255);
  /* overflow: hidden; */
}

.icon-box-address .icon i {
  font-size: 28px;
}

.icon-box-address h4 {
  margin-bottom: 15px;
  font-size: 24px;
  font-family: 'Glacial Indifference', sans-serif;
}

.icon-box-address h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}

.icon-box-address p {
  line-height: 24px;
  font-size: 14px;
  font-family: 'Glacial Indifference', sans-serif;
  margin-bottom: 0;
}

.icon-box-address:hover {
  transform: translateY(-10px);
  border-color: #001b2b;
}

.icon-box-address {
  padding: 25px;
  transition: all ease-in-out 0.3s;
  background: #fff;
  box-shadow: 0px 5px 90px 0px #f0f0ff;
  border-radius: 18px;
  border-bottom: 5px solid #fff;
  /* margin-top: "10%"; */
}

/* .policyprocedurecard:hover {
  transform: translateY(-1px);
  border-color: #0fce19;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* profilre submit button */

.fileUploadInput {
  display: grid;
  grid-gap: 10px;
  position: relative;
  z-index: 1;
}

.fileUploadInput label {
  display: flex;
  align-items: center;
  color: setColor(primary, 0.5);
  background: setColor(white);
  transition: 0.4s ease;
  font-family: "DM Serif Display", serif;
  font-size: 0.75em;
}

.fileUploadInput input {
  position: relative;
  z-index: 1;
  left: -10px;
  /* padding: 0 gap(m); */
  width: 80px;
  height: 20px;
  border: 1px solid #323262;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-family: 'Glacial Indifference', sans-serif;
  user-select: none;
  cursor: pointer;
}

.fileUploadInput input[type="file"] {
  padding: 0 gap(m);
}

.fileUploadInput input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
  margin-left: 10px;
  padding: 0;
  height: 20px;
  width: 0;
}

.fileUploadInput button {
  position: absolute;
  /* right: 0; */
  left: 70px;
  bottom: 0;
  width: 50px;
  height: 20px;
  line-height: 0;
  user-select: none;
  color: white;
  background-color: #00008080;
  border: 1px solid #323262;
  border-radius: 0 3px 3px 0;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 1rem;
}

.fileUploadInput button svg {
  width: auto;
  height: 50%;
}

.integrationinput {
  height: 30px;
  width: 200px;
  border: none;
  border-radius: 10px;
  background-color: #eeeeee;
}

.groupCenter {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.integrationlable {
  width: 150px;
}

.submitintbutton {
  height: 30px;
  width: 100px;
  background-color: #00008080;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  color: #fff;
}

/* ///// ednd*/

/* new profile */
.Basicinfo {
  padding: 20px;
  overflow: hidden;
  /* min-width: 245px; */
  /* min-height: 230px; */
  height: 100%;
  width: 50%;
  /* transform: translateY(-100px); */
  background-color: #fff;
  border-radius: 20px;
}

.main_section1 {
  padding: 20px 40px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* background-color: #000; */
}
.select_border {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0) 0px 2px 3px 0px,
    rgba(27, 31, 35, 0.319) 0px 0px 0px 1px;
  margin-top: -5px;
  color: #000;
}
.select_border:disabled {
  color: #000;
}
/* CSS */
.button-31 {
  float: right;
  /* appearance: none; */
  /* margin-right: 20px; */
  width: fit-content;
  /* height: 40px; */
  /* background-color: #2ea44f; */
  background-color: #001b2b;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 5px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Glacial Indifference', sans-serif;
  /* font-size: 10px; */
  /* line-height: 20px; */
  padding: 6px ;
  /* position: relative; */
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}
.button-31:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}
.button-31:hover {
  /* background-color: #2c974b; */
  background-color: #001b2b;
}

.button-31:focus {
  box-shadow: #001b2b 0 0 0 3px;
  outline: none;
}

.button-31:disabled {
  background-color: #a4abb2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-31:active {
  /* background-color: #298e46; */
  background-color: #001b2b;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}

/* //profile_information */

.st_maindiv {
  width: 35%;
  height: 100%;
}
.nd_maindiv {
  width: 65%;
  height: 100%;
}
.nd_firsthalf {
  /* position: relative; */
  width: 100%;
  height: 40%;
}
.nd_secondhalf {
  position: relative;
  display: flex;
  gap: 4%;
  flex-direction: row;
  width: 100%;
  padding: 2%;
  height: 60%;
  overflow: hidden;
}
.image_grid {
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
  padding: 4%;
  width: 50%;
  background-color: #ffffff;
}
.paddinginput::placeholder {
  /* margin-left: 20px; */
  /* margin: 10px; */
  color: #000;
  text-align: center;
  font-size: small;
}
.paddinginput {
  margin-top: 5%;
  width: 60%;
  justify-content: center;
  font-size: small;
}
.Basicinformation {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-color: #fff;
  border-radius: 15px;
  padding: 10px;
}
.notificationnorap{
  text-wrap: nowrap;
}
.textareainaudit {
  padding: 7px;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  min-height: 60px;
  background-color: transparent;
  border: none;
  /* height: 15%; */
  outline-width: 0.1px;
  outline-color: #0a2433;
  /* outline: none; */
  color: #808080;
  text-overflow: ellipsis;
  outline-style: none;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0) 0px 2px 3px 0px,
    rgba(27, 31, 35, 0.319) 0px 0px 0px 1px;
}
.address_scroll {
  overflow: scroll;
  max-height: 260px;
  padding: 9px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.User_profile_input {
  background-color: #fff;
  gap: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 17px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0) 0px 2px 3px 0px,
    rgba(27, 31, 35, 0.319) 0px 0px 0px 1px;
}

.User_profile_input_nopadding {
  background-color: #fff;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0) 0px 2px 3px 0px,
    rgba(27, 31, 35, 0.319) 0px 0px 0px 1px;
}

.title_seting {
  padding: 10px;
  text-decoration: none;
}
.User_profile_input_isopen {
  margin-bottom: 5px;
  background-color: #eeeeee;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(255, 70, 70) 0px 0px 0px 1px inset;
  width: 100%;
  padding: 7px;
  border-radius: 5px;
}
.validation_text {
  color: #ff1d1d;
  margin: 10px;
  font-size: 12px;
}
.profile_information input::placeholder {
  color: #000000;
}
.profile_informationopen {
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
}
.account_basicinfo {
  margin-bottom: 50px;
}
.profile_informationopen input:disabled {
  color: #000;
}
.profile_informationopen input {
  width: 100%;
  background-color: #fff;
  border: none;
  /* height: 15%; */
  outline-width: 0.1px;
  outline-color: #0a2433;
  /* outline: none; */
  color: #000000;
  text-overflow: ellipsis;
  outline-style: none;
}
.profile_informationopen input::placeholder {
  color: #8a8a8a;
}
.frameworkfooter {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.56) 0px 10px 20px 1px;
}
.auditheadertitle {
  min-width: fit-content;
}

/* CSS */
.button-39 {
  background-color: #ffffff;
  float: right;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #111827;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none #d1d5db solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-39:hover {
  background-color: rgb(249, 250, 251);
}

.button-39:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button-39:focus-visible {
  box-shadow: none;
}
.org_address_list {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.autocomplete-transition {
  transition: all 0.3s ease;
}

.Profile-bg-container-inside {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: transparent;
  overflow: hidden;
}
.textdd {
  text-decoration: none;
  color: #3e3e3e;
}
.avathar_active {
  box-shadow: #5fa4fc 0px 1px 4px, #5fa4fc 0px 0px 0px 3px;
}
.avathar_inactive {
  box-shadow: #bdbdbd 0px 1px 4px, #bdbdbd 0px 0px 0px 3px;
}
.Profile-bg-container-inside :hover {
  cursor: pointer;
}
.userfilterpopuplist:hover {
  background-color: #4286f44d;
  border-radius: 5px;
  cursor: pointer;
}
.Name_mail_sec {
  max-height: 400px;
  overflow: scroll;
  padding: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Name_mail_sec:hover {
  overflow: visible;
}
.Name_mail_sec em {
  font-style: normal;
  color: #000;
  float: right;
}
.image_section {
  /* overflow: hidden; */
  /* margin: 20px; */
  /* margin-left: 8%; */

  /* margin-top: 20px; */
  /* border: none; */
}
.image_section1 {
  overflow: hidden;
  background-color: #fff;

  height: 80px;
  border: none;

  width: 80px;
}

.qwqwqw {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  height: fit-content;
}

/* CSS */

/* CSS */
.button-23 {
  background-color: #ffffff;
  border: 1px solid #001b2bb0;
  padding: 6px;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  color: #001b2bb0;
  cursor: pointer;
  display: inline-block;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 16px;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
}

.button-23:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow 0.2s;
}

.button-23:active {
  background-color: #f7f7f7;
  border-color: #000000;
  transform: scale(0.96);
}

.button-23:disabled {
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
}
.button-23:hover {
  color: #ff5d5d;
  border: 1px solid #ff5d5d;
}
.button-231 {
  background-color: #ffffff;
  border: 1px solid #001b2bb0;
  padding: 6px;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  color: #001b2bb0;
  cursor: pointer;
  display: inline-block;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 16px;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
}
.button-231:hover {
  color: #7be243;
  border: 1px solid #7be243;
}

.button-231:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow 0.2s;
}

.button-231:active {
  background-color: #f7f7f7;
  border-color: #000000;
  transform: scale(0.96);
}

.button-231:disabled {
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
}
.process_card {
  background-color: #f3f3f3;
  box-shadow: #a4abb2 0px 1px 4px;
}
.process_card:hover {
  cursor: grab;
}
.process_card:active {
  cursor: grabbing;
}
.loader {
  animation: rotate 1s infinite;
  margin: auto;
  justify-content: center;
  margin-top: 23%;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #0f2e40;
  box-shadow: 30px 0 0 #bdcedb;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #1878c6;
  box-shadow: 30px 0 0 #b92c2c;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #1878c6;
  }
  50% {
    box-shadow: 0 0 0 #dbbdbd;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #1878c6;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #0f2e40;
  }
  50% {
    box-shadow: 0 0 0 #0f2e40;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #0f2e40;
    margin-top: 0;
  }
}

.wrapper_TopNav {
  margin-top: 10px;
  position: relative;
  /* width: 100px; */
}

.icon_TopNav {
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  box-sizing: border-box;
  top: 42%;
  left: 10px;
  transform: translateY(-50%);
}

.input_TopNav {
  color: #001b2b;
  /* width: 500px; */

  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  height: 32px;
  padding-left: 2.5rem;
  border: none;
}
.input_TopNav:focus {
  color: #001b2b;
  width: 500px;
  /* outline: none !important; */
  outline-color: #4285f4;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  height: 30px;
  border: none;
}
/* Define styles for the input when focused */

.Autocompleter-input {
  overflow: hidden;
  min-width: 500px;
}

.Autocompleter-paper {
  background-color: #000;
  width: 200px;
}
.Autocompleter-paperonfocus {
  width: 400px;
  background-color: #000;
}
.Autocompleter-option {
  min-width: 200px;
  max-width: 200px;
}
.Autocompleter-optionnonfocus {
  min-width: 400px;
  max-width: 401px;
}
.unique-ellipsis-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.unique-ellipsis-spinner div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px;
  animation: bounce 1s ease-in-out infinite;
}

.unique-ellipsis-spinner div:nth-of-type(1) {
  animation-delay: -0.32s;
}

.unique-ellipsis-spinner div:nth-of-type(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes dot-loader {
  0%,
  80%,
  100% {
    opacity: 0.4;
    transform: scale(0.8);
  }

  40% {
    opacity: 1;
    transform: scale(1.2);
  }
}

.dot-loader {
  display: inline-block;
  margin-top: -2px;
}

.dot-loader span {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
  margin: 0 4px;
  animation: dot-loader 1.4s infinite ease-in-out both;
}

.dot-loader span:nth-child(1) {
  animation-delay: 0s;
}

.dot-loader span:nth-child(2) {
  animation-delay: 0.2s;
}

.dot-loader span:nth-child(3) {
  animation-delay: 0.4s;
}
.container1233 {
  width: 100px;
  height: 100px;
  position: relative;
  animation: flyingHummingbird 2s linear infinite;
}

.bird {
  width: 100%;
  height: 100%;
}

@keyframes flyingHummingbird {
  0% {
    transform: translateX(0) translateY(0) rotate(0);
  }
  25% {
    transform: translateX(100%) translateY(0) rotate(45deg);
  }
  50% {
    transform: translateX(100%) translateY(100%) rotate(90deg);
  }
  75% {
    transform: translateX(0) translateY(100%) rotate(135deg);
  }
  100% {
    transform: translateX(0) translateY(0) rotate(180deg);
  }
}
.boxa4 {
  width: 185mm; /* A4 width */
  margin: auto;
}
.a4-container {
  margin: auto;
  width: 165mm; /* A4 width */
  padding: 25px; /* Padding to mimic page margin */
  background-color: white;
}

.a4-container h1 {
  background-color: #0f2e40;
  border-radius: 5px;
  padding: 30px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 30px;
}

/* Apply styles to the entire table */
.a4-container table {
  border-collapse: collapse;
  width: 100%;
  max-width: 600px; /* Adjust the maximum width as needed */
  margin: 20px auto;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
    #ccc 0px 0px 0px 1px;
}
@media print {
  #print-content {
    display: block;
  }
  .a4-container {
    margin: auto;
    width: 165mm; /* A4 width */
    background-color: white;
  }
  .a4-container h1 {
    background-color: #0f2e40;
    border-radius: 5px;
    padding: 30px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .a4-container table {
    border-collapse: collapse;
    width: 100%;
    max-width: 600px; /* Adjust the maximum width as needed */
    margin: 20px auto;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
      #ccc 0px 0px 0px 1px;
  }
  .a4-container tr {
    border-bottom: 1px solid #ccc;
  }

  /* Apply styles to table cells */
  .a4-container td {
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    border-right: 1px solid #ccc;
  }
  /* Apply styles to divs within table cells */

  .a4-container td > div {
    padding: 5px 10px;
  }

  /* Apply additional styles to specific cells */
  /* For example, first row cells */
  .a4-container tr:first-child td {
    font-weight: bold;
  }
  /* Add the necessary styles for the printed content here */
}

/* Apply styles to table rows */
.a4-container tr {
  border-bottom: 1px solid #ccc;
}

/* Apply styles to table cells */
.a4-container td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #ccc;
}

/* Apply styles to divs within table cells */
.a4-container td > div {
  padding: 5px 10px;
}
/* Apply additional styles to specific cells */
/* For example, first row cells */
.a4-container tr:first-child td {
  font-weight: bold;
}
/* CoverPage.css */
.assesmentliststyle{
  border: none;
}
.cover-page {
  width: 185mm; /* A4 width */
  height: 255mm; /* A4 height */
  padding: 20px;
  display: flex;
  margin: auto;
  flex-direction: column;
  font-family: Arial, sans-serif;
  color: #001b2b;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  scroll-behavior: smooth;
}
.cover-page h1 {
  justify-content: center;
  margin: auto;
  font-weight: bolder;
}
.cover-page img {
  position: absolute;
  /* Position the image absolutely */
  top: 10;
  /* Place at the top */
  right: 0;
  /* Place at the right */
  height: 10%;
  /* Set the height as desired */
}
.background {
  background-color: #001b2be6;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
}
.company-name {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}
.document-title {
}
.author-name,
.date {
  font-size: 24px;
  margin: 10px 0;
}


.inputmap {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.firstletterhover:hover {
  transform: scale(1.2); /* Adjust the scale factor as needed */
  transition: transform 0.3s ease-in-out; /* Add a smooth transition */
}
.boxa4 li {
  margin-left: 30px;
}
.checkbox-wrapper-30 .checkbox {
  --bg: #fff;
  --brdr: #d1d6ee;
  --brdr-actv: #1e2235;
  --brdr-hovr: #bbc1e1;
  --dur: calc((var(--size, 2)/2) * 0.6s);
  display: inline-block;
  width: calc(var(--size, 1) * 29px);
  justify-content: center;
  margin: auto;margin-top: 6px;
  position: relative;
}

.checkbox-wrapper-30 .checkbox:after {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

.checkbox-wrapper-30 .checkbox>* {
  position: absolute;
}

.checkbox-wrapper-30 .checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background-color: var(--bg);
  border-radius: calc(var(--size, 1) * 4px);
  border: calc(var(--newBrdr, var(--size, 1)) * 1px) solid;
  color: var(--newBrdrClr, var(--brdr));
  outline: none;
  margin: 0;
  padding: 0;
  transition: all calc(var(--dur) / 3) linear;
}

.checkbox-wrapper-30 .checkbox input:hover,
.checkbox-wrapper-30 .checkbox input:checked {
  --newBrdr: calc(var(--size, 1) * 2);
}

.checkbox-wrapper-30 .checkbox input:hover {
  --newBrdrClr: var(--brdr-hovr);
}

.checkbox-wrapper-30 .checkbox input:checked {
  --newBrdrClr: var(--brdr-actv);
  transition-delay: calc(var(--dur) /1.3);
}

.checkbox-wrapper-30 .checkbox input:checked+svg {
  --dashArray: 16 93;
  --dashOffset: 109;
}

.checkbox-wrapper-30 .checkbox svg {
  fill: none;
  left: 0;
  pointer-events: none;
  stroke: var(--stroke, var(--border-active));
  stroke-dasharray: var(--dashArray, 93);
  stroke-dashoffset: var(--dashOffset, 94);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  top: 0;
  transition: stroke-dasharray var(--dur), stroke-dashoffset var(--dur);
}

.checkbox-wrapper-30 .checkbox svg,
.checkbox-wrapper-30 .checkbox input {
  display: block;
  height: 100%;
  width: 100%;
}
/* FilterForm.css */

/* Reset box-sizing */



.form11 {
  background: #2a2a2a;
  border-radius: 10px;
  display: inline-block;
  margin: 2em auto;
  padding: 1em;
  text-align: left;
}

.header12 {
  
  color: #aaa;
  font-size: 1em;
  letter-spacing: 1px;
  margin-bottom: 1em;
}
@keyframes backgroundAnimation {
  0% {
    background-color: rgba(0, 255, 0, 0.5);
  }
  100% {
    background-color: rgba(238, 238, 238, 0.5);
  }
}

@keyframes gradientAnimation {
  0% {
    background-size: 0% 100%; /* Start with no gradient */
  }
  100% {
    background-size: 100% 100%; /* Full gradient at 100% */
  }
}

/* Checkbox styles */
#easy+label:before {
  background-color: rgba(0, 128, 0, 0.5);
  /* Green */
}

#medium+label:before {
  background-color: rgba(255, 165, 0, 0.5);
  /* Orange */
}

#hard+label:before {
  background-color: rgba(255, 0, 0, 0.5);
  /* Red */
}
/* Filter styles */
.filter {
  display: flex;
  flex-direction: row;
gap: 20px;  
color: #000;
}
.borderr{
  border-radius: 5px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
}

/* Bourbon Import */
/* Bourbon styles would go here */


/* cheakbox" */
.checkbox-wrapper-47 input[type="checkbox"] {
  z-index: 10;
  display: none;
  visibility: hidden;
    background: transparent;
      /* Or any other color you want */
}

.checkbox-wrapper-47 label {
    z-index: 2;
  position: relative;
  padding-left: 2em;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
  display: inline-flex;text-wrap: nowrap;
}

.checkbox-wrapper-47 label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 0.3em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid #9098A9;
  border-radius: 6px;
  z-index: -1;
}

/* loading start */
/* loading end */
.checkbox-wrapper-47 input[type=checkbox]:checked+label {
  padding-left: 1em;
  color: #0f5229;
}

.checkbox-wrapper-47 input[type=checkbox]:checked+label:before {
  top: 0;
  width: 100%;
  height: 2em;
  background: #b6d1e2;
  border-color: #001b2b;
}

.checkbox-wrapper-47 label,
.checkbox-wrapper-47 label::before {
  transition: 0.25s all ease;
}

/* end  cheakbox" */

.controlpagefilter{
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 200ms;
}

/* .scrollbarrtopdesiden */
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background: #001b2b;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
  margin: auto;
justify-content: center;
padding: 30;
}

.icaoo {
  font-size: 24px;
  color: #ffffff;
  display: flex;
  line-height: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  justify-content: center;
  z-index: 1000000;
  margin: auto;
}

.scroll-top:hover {
  background: #4285f4;
  color: #000;
}

.scroll-top.active1 {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}
/* .scrollbarrtopdesiden end */


/* version css? */
.version-container {
  margin: auto;
  border: 1px solid #ddd;
  padding: 20px;
  
  border-radius: 8px;
  background: #fff;
}
.MuiTimelineContent-root {
  width: 100% !important;
}
.version-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.version-header h1 {
  margin: 0;
  font-size: 1.5em;
  color: #333;
}

.editor-info {
  display: flex;
  flex-direction: row;
  color: #666;
  font-size: 0.85em;
}

.version-actions {
  margin-bottom: 20px;
  display: flex;
  float:right;
  gap: 10px;
}
.headerofversion{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.active-button,
.upload-button,
.pdf-button {
  height: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.active-button {
  background-color: #4caf50;
  color: white;
}

.upload-button {
  background-color: #f0f0f0;
  color: #333;
}

.pdf-button {
  background-color: #e7e7e7;
  color: #333;
}

.version-content {
  max-height: 200px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; 
  padding: 30px;
box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;  background-color: #f1f1f1;
  margin-bottom: -20px;
  /* Additional styles for content */
}

.version-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.checkbox-wrapper-10 .tgl {
  display: none;
}

.checkbox-wrapper-10 .tgl,
.checkbox-wrapper-10 .tgl:after,
.checkbox-wrapper-10 .tgl:before,
.checkbox-wrapper-10 .tgl *,
.checkbox-wrapper-10 .tgl *:after,
.checkbox-wrapper-10 .tgl *:before,
.checkbox-wrapper-10 .tgl+.tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-10 .tgl::-moz-selection,
.checkbox-wrapper-10 .tgl:after::-moz-selection,
.checkbox-wrapper-10 .tgl:before::-moz-selection,
.checkbox-wrapper-10 .tgl *::-moz-selection,
.checkbox-wrapper-10 .tgl *:after::-moz-selection,
.checkbox-wrapper-10 .tgl *:before::-moz-selection,
.checkbox-wrapper-10 .tgl+.tgl-btn::-moz-selection,
.checkbox-wrapper-10 .tgl::selection,
.checkbox-wrapper-10 .tgl:after::selection,
.checkbox-wrapper-10 .tgl:before::selection,
.checkbox-wrapper-10 .tgl *::selection,
.checkbox-wrapper-10 .tgl *:after::selection,
.checkbox-wrapper-10 .tgl *:before::selection,
.checkbox-wrapper-10 .tgl+.tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-10 .tgl+.tgl-btn {
  outline: 0;
  display: block;
  width: 12em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper-10 .tgl+.tgl-btn:after,
.checkbox-wrapper-10 .tgl+.tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-10 .tgl+.tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-10 .tgl+.tgl-btn:before {
  display: none;
}

.checkbox-wrapper-10 .tgl:checked+.tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-10 .tgl-flip+.tgl-btn {
  padding: 2px;
  transition: all 0.2s ease;
  font-family: sans-serif;
  perspective: 100px;
}

.checkbox-wrapper-10 .tgl-flip+.tgl-btn:after,
.checkbox-wrapper-10 .tgl-flip+.tgl-btn:before {
  display: inline-block;
  transition: all 0.4s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
}

.checkbox-wrapper-10 .tgl-flip+.tgl-btn:after {
  content: attr(data-tg-on);
  background: #02C66F;
  transform: rotateY(-180deg);
}

.checkbox-wrapper-10 .tgl-flip+.tgl-btn:before {
  background: #FF3A19;
  content: attr(data-tg-off);
}

.checkbox-wrapper-10 .tgl-flip+.tgl-btn:active:before {
  transform: rotateY(-20deg);
}

.checkbox-wrapper-10 .tgl-flip:checked+.tgl-btn:before {
  transform: rotateY(180deg);
}

.checkbox-wrapper-10 .tgl-flip:checked+.tgl-btn:after {
  transform: rotateY(0);
  left: 0;
  background: #7FC6A6;
}

.checkbox-wrapper-10 .tgl-flip:checked+.tgl-btn:active:after {
  transform: rotateY(20deg);
}


/* end version css/ */
/* button css */
/* CSS */
.button-26 {
  background: #fff;
  border: 2px solid green;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  outline: none;
  padding: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15), 5px 14px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.1s ease-in-out;
}

.button-26__content {
  padding: 4px 40px;
  border-radius: 5px;
  /* box-shadow: inset 0 0px #d5d7de, 0 0px #ffffff; */
  transition: all 0.1s ease-in-out;
}

.button-26__text {
  text-transform:uppercase;
  color: green;
  display: block;
  /* transform: translate3d(0, -4px, 0); */
  transition: all 0.1s ease-in-out;
}

.button-26:active {
  box-shadow: none;
}

.button-26:active .button-26__content {
  box-shadow: none;
}

.button-26:active .button-26__text {
  transform: translate3d(0, 0, 0);
}
/* end button css */
.container123 {
  height: 100%;
  /* width: 100%; */
  padding: 2%;
  /* transform: translateY(-100px); */
  background-color: #fff;
  border-radius: 15px;
  max-height: fit-content;
  /* min-height: 345px; */
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; overflow: hidden;
}
.avaava{
  
box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;}

/* start Dashboard */
.dashboardcards {
  /* Other styles for your dashboard cards */

  /* Define initial box-shadow */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px 0px;

  /* Define transition property for smooth transition */
  /* transition: box-shadow 1s ease-in-out; */
  /* Adjust timing function and duration as needed */
}

.dashboardcards:hover {
  /* Adjusted box-shadow for hover state */
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;    transition: box-shadow 0.3s ease-in-out;
}